import { createAction } from '@reduxjs/toolkit';
import type { WGetCartView } from '@zola/svc-web-api-ts-client';
import type { CamelCasedPropertiesDeep } from 'type-fest';

const REQUEST_SIZE = 'zola/cart/REQUEST_SIZE';
const RECEIVE_SIZE = 'zola/cart/RECEIVE_SIZE';
const REQUEST_CART = 'zola/cart/REQUEST_CART';
const RECEIVE_CART = 'zola/cart/RECEIVE_CART';
const UNLOAD_CART = 'zola/cart/UNLOAD_CART';

export const requestCartSizeAction = createAction(REQUEST_SIZE);
export const receivedCartSizeAction = createAction<number>(RECEIVE_SIZE);
export const requestCartAction = createAction(REQUEST_CART);
export const receiveCartAction = createAction<CamelCasedPropertiesDeep<WGetCartView>>(RECEIVE_CART);
export const unloadCartAction = createAction(UNLOAD_CART);
