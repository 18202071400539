import { AnyAction } from 'redux';
import type { CamelCasedPropertiesDeep } from 'type-fest/index';
import type { WGetCartView, WCartItemDetailView } from '@zola/svc-web-api-ts-client';
import {
  receiveCartAction,
  receivedCartSizeAction,
  requestCartAction,
  requestCartSizeAction,
  unloadCartAction,
} from 'actions/types/CartActionTypes';

export type CartItemType = CamelCasedPropertiesDeep<WCartItemDetailView>;
export type CartTotalsType = CamelCasedPropertiesDeep<WGetCartView['totals']>;
export type FreeShippingType = CamelCasedPropertiesDeep<WGetCartView['free_shipping']>;

type CartState = {
  busy: boolean;
  initialized: boolean;
  size: number;
  items: CartItemType[];
  totals: CartTotalsType;
  isFreeShippingEligible: boolean;
  freeShipping: FreeShippingType | null;
  cartId?: string;
  registryId?: string;
};

const initialState: CartState = {
  busy: false,
  initialized: false,
  size: 0, // sometimes we will only have this
  items: [],
  totals: {},
  isFreeShippingEligible: false,
  freeShipping: null,
};

const cartReducer = (state = initialState, action: AnyAction): CartState => {
  if (requestCartSizeAction.match(action) || requestCartAction.match(action)) {
    return { ...state, busy: true };
  }

  if (receivedCartSizeAction.match(action)) {
    return { ...state, busy: false, size: action.payload };
  }

  if (receiveCartAction.match(action)) {
    return {
      ...state,
      busy: false,
      initialized: true,
      size: action.payload.items?.length || 0,
      items: action.payload.items || [],
      totals: action.payload.totals,
      freeShipping: action.payload.freeShipping,
      cartId: action.payload.cartId,
      registryId: action.payload.registryId,
      /** @ts-expect-error: This property doesn't seem to exist, but kept here during the TS/redux-tool kit conversion just in case */
      isFreeShippingEligible: action.payload.is_free_shipping_eligible,
    };
  }

  if (unloadCartAction.match(action)) {
    return { ...initialState, size: state.size };
  }

  return state;
};

export default cartReducer;
